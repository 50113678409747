
import { BButton, BCard } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    BButton,
    BCard,
  },
})
export default class IndexPage extends Vue {}
